const reactCompat = require('preact-compat')
const createContext = require('preact-context').createContext

reactCompat.createContext = createContext

if (reactCompat.default) {
  reactCompat.default.createContext = createContext
}

if (typeof process !== 'undefined' && typeof process.env.EMULATE_SUSPENSE !== 'undefined') {
  reactCompat.Suspense = function() {}
}

module.exports = reactCompat
