import { Component } from 'react';

import {
  addComponent,
  removeComponent,
  watchComponent,
  updateQuery,
  setQueryOptions,
  setQueryListener,
} from '@appbaseio/reactivecore/lib/actions';
import {
  getQueryOptions,
  pushToAndClause,
  checkValueChange,
  getAggsOrder,
  isEqual,
  checkPropChange,
  checkSomePropChange,
  getClassName,
} from '@appbaseio/reactivecore/lib/utils/helper';

import types from '@appbaseio/reactivecore/lib/utils/types';

import Title from '../forked/Title';
import Container from '@appbaseio/reactivesearch/lib/styles/Container';
import Dropdown from '@appbaseio/reactivesearch/lib/components/shared/Dropdown';
import { connect } from '@appbaseio/reactivesearch/lib/utils';

class SkrSingleDropdownList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.selectedValue || '',
      options: props.options[props.dataField]
        ? props.options[props.dataField].buckets
        : [],
    };

    this.locked = false;
    this.internalComponent = `${props.componentId}__internal`;
    props.setQueryListener(props.componentId, props.onQueryChange, null);
  }

  componentWillMount() {
    this.props.addComponent(this.internalComponent);
    this.props.addComponent(this.props.componentId);
    this.updateQueryOptions(this.props);

    this.setReact(this.props);

    if (this.state.currentValue !== this.props.selectedValue) {
      this.setValue(this.props.selectedValue);
    } else if (this.props.defaultSelected) {
      this.setValue(this.props.defaultSelected);
    }
  }

  componentWillReceiveProps(nextProps) {
    checkPropChange(
      this.props.react,
      nextProps.react,
      () => {
        this.setReact(nextProps)}
    );
    checkPropChange(
      this.props.options,
      nextProps.options,
      () => {
        this.setState({
          options: nextProps.options[nextProps.dataField]
            ? nextProps.options[nextProps.dataField].buckets
            : [],
        });
      },
    );
    checkSomePropChange(
      this.props,
      nextProps,
      ['size', 'sortBy'],
      () => this.updateQueryOptions(nextProps),
    );

    checkPropChange(
      this.props.dataField,
      nextProps.dataField,
      () => {
        this.updateQueryOptions(nextProps);
        this.updateQuery(this.state.currentValue, nextProps);
      },
    );

    if (this.props.defaultSelected !== nextProps.defaultSelected) {
      this.setValue(nextProps.defaultSelected);
    } else if (this.state.currentValue !== nextProps.selectedValue) {
      this.setValue(nextProps.selectedValue || '');
    }
  }

  componentDidMount() {
    /*if (this.props.options[this.props.dataField] && !isEqual(this.props.options, this.state.options)) {
      this.setState({ options: this.props.options[this.props.dataField].buckets });
    }*/
  }

  componentWillUnmount() {
    this.props.removeComponent(this.props.componentId)
    this.props.removeComponent(this.internalComponent)
  }

  setReact = (props) => {
    const { react } = props;
    if (react) {
      const newReact = pushToAndClause(react, this.internalComponent);
      props.watchComponent(props.componentId, newReact);
    } else {
      props.watchComponent(props.componentId, { and: this.internalComponent });
    }
  };

  static defaultQuery = (value, props) => {
    if (props.selectAllLabel && props.selectAllLabel === value) {
      if (props.showMissing) {
        return { match_all: {} };
      }
      return {
        exists: {
          field: props.dataField,
        },
      };
    } else if (value) {
      if (props.showMissing && props.missingLabel === value) {
        return {
          bool: {
            must_not: {
              exists: { field: props.dataField },
            },
          },
        };
      }
      return {
        term: {
          [props.dataField]: value,
        },
      };
    }
    return null;
  }

  setValue = (value, props = this.props) => {
    // ignore state updates when component is locked
    if (props.beforeValueChange && this.locked) {
      return;
    }

    if (this.props.selectAllLabel && value === this.props.selectAllLabel) {
      value = ""
    }

    this.locked = true;
    const performUpdate = () => {
      this.setState({
        currentValue: value,
      }, () => {
        this.updateQuery(value, props);
        this.locked = false;
        if (props.onValueChange) props.onValueChange(value);
      });
    };

    checkValueChange(
      props.componentId,
      value,
      props.beforeValueChange,
      performUpdate,
    );
  };

  updateQuery = (value, props) => {
    const query = props.customQuery || SkrSingleDropdownList.defaultQuery;

    props.updateQuery({
      componentId: props.componentId,
      query: query(value, props),
      value,
      label: props.filterLabel,
      showFilter: props.showFilter,
      URLParams: props.URLParams,
    });
  };

  static generateQueryOptions(props) {
    const queryOptions = getQueryOptions(props);
    queryOptions.aggs = {
      [props.dataField]: {
        terms: {
          field: props.dataField,
          size: props.size,
          order: getAggsOrder(props.sortBy || 'count'),
          ...(props.showMissing ? { missing: props.missingLabel } : {}),
        },
      },
    };
    return queryOptions;
  }

  updateQueryOptions = (props) => {
    const queryOptions = SkrSingleDropdownList.generateQueryOptions(props);
    props.setQueryOptions(this.internalComponent, queryOptions);
  };

  render() {
    let selectAll = [];

    if (this.props.selectAllLabel && this.state.currentValue !== "") {
      selectAll = [{
        key: this.props.selectAllLabel,
      }];
    }

    return (
      <Container style={this.props.style} className={this.props.className} data-gtm-selector={this.props.gtmSelector}>
        {this.props.title && <Title className={getClassName(this.props.innerClass, 'title') || null}>{this.props.title}</Title>}
        <Dropdown
          innerClass={this.props.innerClass}
          items={
            [
              ...selectAll,
              ...this.state.options
              .filter(item => String(item.key).trim().length)
              .sort((a, b) => (a.key.localeCompare(b.key)))
              .map(item => ({ ...item, key: String(item.key) })),
            ]
          }
          onChange={this.setValue}
          selectedItem={this.state.currentValue}
          placeholder={this.props.selectAllLabel || this.props.placeholder}
          labelField="key"
          showCount={this.props.showCount}
          themePreset={this.props.themePreset}
          renderListItem={this.props.renderListItem}
        />
      </Container>
    );
  }
}

SkrSingleDropdownList.propTypes = {
  addComponent: types.funcRequired,
  removeComponent: types.funcRequired,
  setQueryListener: types.funcRequired,
  setQueryOptions: types.funcRequired,
  updateQuery: types.funcRequired,
  watchComponent: types.funcRequired,
  options: types.options,
  selectedValue: types.selectedValue,
  // component props
  beforeValueChange: types.func,
  className: types.string,
  componentId: types.stringRequired,
  customQuery: types.func,
  dataField: types.stringRequired,
  defaultSelected: types.string,
  filterLabel: types.string,
  innerClass: types.style,
  onQueryChange: types.func,
  onValueChange: types.func,
  placeholder: types.string,
  react: types.react,
  renderListItem: types.func,
  selectAllLabel: types.string,
  showCount: types.bool,
  showFilter: types.bool,
  size: types.number,
  sortBy: types.sortByWithCount,
  style: types.style,
  title: types.title,
  themePreset: types.themePreset,
  URLParams: types.bool,
  showMissing: types.bool,
  missingLabel: types.string,
  gtmSelector: types.string,
};

SkrSingleDropdownList.defaultProps = {
  className: null,
  placeholder: 'Select a value',
  showFilter: true,
  sortBy: 'count',
  style: {},
  showMissing: false,
  missingLabel: 'N/A',
  selectAllLabel: 'Alle',
  size: 300,
  showCount: false,
  URLParams: true,
  innerClass: {title: 'acts-as-label', select: 'acts-as-select'},
  onQueryChange: (oldQuery, newQuery) => {
    // // console.log(this.props)
    // newQuery.query = newQuery.query || {}
    // newQuery.query.bool = newQuery.query.bool || {}
    // newQuery.query.bool.must = newQuery.query.bool.must || []
    // newQuery.query.match_all && delete newQuery.query.match_all
    // newQuery.query.bool.must.push({'match': {'type': 'trip'}})
    newQuery.size = 0
  },
}

const mapStateToProps = (state, props) => {
  return {
    options: state.aggregations[props.componentId],
    selectedValue: (state.selectedValues[props.componentId] && state.selectedValues[props.componentId].value) || '',
    themePreset: state.config.themePreset,
  }
}

const mapDispatchtoProps = dispatch => ({
  addComponent: component => dispatch(addComponent(component)),
  removeComponent: component => dispatch(removeComponent(component)),
  setQueryOptions: (component, props) => dispatch(setQueryOptions(component, props)),
  setQueryListener: (component, onQueryChange, beforeQueryChange) =>
    dispatch(setQueryListener(component, onQueryChange, beforeQueryChange)),
  updateQuery: updateQueryObject => dispatch(updateQuery(updateQueryObject)),
  watchComponent: (component, react) => dispatch(watchComponent(component, react)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(SkrSingleDropdownList);
