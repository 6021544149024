export function getSettings() {
  let baseUrl = 'http://localhost:9200'
  if (typeof process !== "undefined" && typeof process.env !== "undefined" && typeof process.env.BASE_URL !== "undefined") {
    baseUrl = process.env.BASE_URL
  }
  if (typeof window !== "undefined") {
    baseUrl = (window.location.origin ? window.location.origin + '/' : window.location.protocol + '//' + window.location.host + '/').replace(/\/+$/,'') + '/_search'
  }
  return {
    app: 'skr',
    url: baseUrl,
    theme: {
      typography: {
        fontFamily: '"fsme", "Helvetica", Helvetica, Arial, sans-serif', fontSize: '1rem', fontWeight: 400,
      }, colors: {
        textColor: '#000', primaryColor: '#e6442e', titleColor: '#4d4d4d',
      },
    },
  }
}
