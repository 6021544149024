import { Component } from 'react';

import {
  addComponent,
  removeComponent,
  watchComponent,
  updateQuery,
  setQueryListener,
} from '@appbaseio/reactivecore/lib/actions';
import {
  isEqual,
  checkValueChange,
  checkPropChange,
  getClassName,
} from '@appbaseio/reactivecore/lib/utils/helper';

import types from '@appbaseio/reactivecore/lib/utils/types';

import Title from '../forked/Title';
import Container from '@appbaseio/reactivesearch/lib/styles/Container';
import Dropdown from '@appbaseio/reactivesearch/lib/components/shared/Dropdown';
import { connect } from '@appbaseio/reactivesearch/lib/utils';

class SkrSingleDropdownRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: null,
    };
    this.type = 'range';
    this.locked = false;
    props.setQueryListener(props.componentId, props.onQueryChange, null);
  }

  componentWillMount() {
    this.props.addComponent(this.props.componentId);
    this.setReact(this.props);

    if (this.props.selectedValue) {
      this.setValue(this.props.selectedValue, true);
    } else if (this.props.defaultSelected) {
      this.setValue(this.props.defaultSelected, true);
    }
  }

  componentWillReceiveProps(nextProps) {
    checkPropChange(
      this.props.react,
      nextProps.react,
      () => this.setReact(nextProps),
    );

    checkPropChange(this.props.dataField, nextProps.dataField, () => {
      this.updateQuery(this.state.currentValue, nextProps);
    });

    if (!isEqual(this.props.defaultSelected, nextProps.defaultSelected)) {
      this.setValue(nextProps.defaultSelected, true);
    } else if (!isEqual(this.state.currentValue, nextProps.selectedValue)) {
      this.setValue(nextProps.selectedValue, true);
    }
  }

  componentWillUnmount() {
    this.props.removeComponent(this.props.componentId);
  }

  setReact(props) {
    if (props.react) {
      props.watchComponent(props.componentId, props.react);
    }
  }

  // parses range label to get start and end
  static parseValue = (value, props) => props.data.find(item => item.label === value) || null

  static defaultQuery = (value, props) => {
    if (value) {
      return {
        range: {
          [props.dataField]: {
            gte: value.start,
            lte: value.end,
            boost: 2.0,
          },
        },
      };
    }
    return null;
  };

  setValue = (value, isDefaultValue = false, props = this.props) => {
    // ignore state updates when component is locked
    if (props.beforeValueChange && this.locked) {
      return;
    }

    if (this.props.selectAllLabel && typeof(value) === "object" && value.start !== undefined && value.end !== undefined && value.start === 0 && value.end === Infinity) {
      value = null
    }

    this.locked = true;
    let currentValue = value;
    if (isDefaultValue) {
      currentValue = props.data.find(item => item.label === value) || null;
      currentValue = SkrSingleDropdownRange.parseValue(value, props);
    }

    const performUpdate = () => {
      this.setState({
        currentValue,
      }, () => {
        this.updateQuery(currentValue, props);
        this.locked = false;
        if (props.onValueChange) props.onValueChange(currentValue);
      });
    };

    checkValueChange(
      props.componentId,
      currentValue,
      props.beforeValueChange,
      performUpdate,
    );
  };

  updateQuery = (value, props) => {
    const query = props.customQuery || SkrSingleDropdownRange.defaultQuery;

    props.updateQuery({
      componentId: props.componentId,
      query: query(value, props),
      value,
      label: props.filterLabel,
      showFilter: props.showFilter,
      URLParams: props.URLParams,
    });
  };

  render() {
    let selectAll = [];

    if (this.props.selectAllLabel && this.state.currentValue !== null) {
      selectAll = [{
        start: 0,
        end: Infinity,
        label: this.props.selectAllLabel,
      }];
    }

    return (
      <Container style={this.props.style} className={this.props.className}>
        {this.props.title && <Title className={getClassName(this.props.innerClass, 'title') || null}>{this.props.title}</Title>}
        <Dropdown
          innerClass={this.props.innerClass}
          items={
            [
              ...selectAll,
              ...this.props.data
            ]
          }
          onChange={this.setValue}
          selectedItem={this.state.currentValue}
          placeholder={this.props.selectAllLabel || this.props.placeholder}
          keyField="label"
          returnsObject
          themePreset={this.props.themePreset}
        />
      </Container>
    );
  }
}

SkrSingleDropdownRange.propTypes = {
  addComponent: types.funcRequired,
  removeComponent: types.funcRequired,
  setQueryListener: types.funcRequired,
  updateQuery: types.funcRequired,
  watchComponent: types.funcRequired,
  selectedValue: types.selectedValue,
  // component props
  beforeValueChange: types.func,
  className: types.string,
  componentId: types.stringRequired,
  customQuery: types.func,
  data: types.data,
  dataField: types.stringRequired,
  defaultSelected: types.string,
  filterLabel: types.string,
  innerClass: types.style,
  onQueryChange: types.func,
  onValueChange: types.func,
  placeholder: types.string,
  react: types.react,
  selectAllLabel: types.string,
  showFilter: types.bool,
  style: types.style,
  title: types.title,
  themePreset: types.themePreset,
  URLParams: types.bool,
}

SkrSingleDropdownRange.defaultProps = {
  className: null,
  placeholder: 'Select a value',
  showFilter: true,
  style: {},
  URLParams: false,
}

const mapStateToProps = (state, props) => ({
  selectedValue: (
    state.selectedValues[props.componentId]
    && state.selectedValues[props.componentId].value
  ) || null,
  themePreset: state.config.themePreset,
})

const mapDispatchtoProps = dispatch => ({
  addComponent: component => dispatch(addComponent(component)),
  removeComponent: component => dispatch(removeComponent(component)),
  updateQuery: updateQueryObject => dispatch(updateQuery(updateQueryObject)),
  watchComponent: (component, react) => dispatch(watchComponent(component, react)),
  setQueryListener: (component, onQueryChange, beforeQueryChange) =>
    dispatch(setQueryListener(component, onQueryChange, beforeQueryChange)),
})

export default connect(mapStateToProps, mapDispatchtoProps)(SkrSingleDropdownRange)
